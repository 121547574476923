<template>
  <div>
    <div v-if="detail" class="p-10">
      <div>
        {{ $t('deployAddress') + $t('colon') + (detail.deployAddress || '') }}
      </div>
    </div>
    <van-list v-model="loading" :finished="finished" :immediate-check="false" @load="onLoad">
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        :title="item.productName"
      >
        <template #label>
          <div class="f-cb">
            <div>SKU{{ $t('colon') + item.productCode }}</div>
            <div>
              {{ $t('channelLabelCode') + $t('colon') + item.channelLabelCode }}
            </div>
          </div>
          <div class="f-cb">
            <div>
              {{ $t('channelCapacity') + $t('colon') + item.channelCapacity }}
            </div>
            <div>{{ $t('currentNum') + $t('colon') + item.currentNum }}</div>
          </div>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: null,
      page: 1,
      list: [],
      loading: false,
      finished: false,
    }
  },
  created() {
    this.findVmBasicInfo()
    this.onLoad()
  },
  methods: {
    async findVmBasicInfo() {
      const { vmCode } = this.$route.query
      const { data } = await this.$api.findVmBasicInfo({ vmCode })
      console.log('findVmBasicInfo===>', data)
      this.detail = data
    },
    async onLoad() {
      // 异步更新数据
      this.loading = true
      const { page } = this
      const { vmCode } = this.$route.query
      const { data } = await this.$api.listVmChannelStockPage({
        vmCode,
        page,
      })
      // 加载状态结束
      this.loading = false
      const {
        rows,
        page: { totalPage },
      } = data
      console.log('listVmChannelStockPage===>', rows)
      this.list = this.list.concat(rows)
      // 数据全部加载完成
      if (totalPage === page || totalPage ===0) {
        this.finished = true
      } else {
        this.page++
      }
    },
  },
}
</script>